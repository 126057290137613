import { CHROME_RUNTIME_PATH, CLOSE_ICON, JWPLAYER_KEY, MAGNITE_ICON, MAGNITE_ICON_URL, MPS_API } from "@/constants";
import { Config, Player } from "@/types";
import { logConsole } from "@/utils";
import { parseBanners } from "@player/banners";
import skin from "@player/skin.sass";

const getAdParams = async (container: HTMLElement, routers: { [key: string]: string }, language: string, policies: { [key: string]: string }, iframeWindow: Window) => {
  let params = {
    // "w": String(container.offsetWidth),
    "w": "500",
    "url": window.location.href,
    "ua": navigator.userAgent,
    "content_genre": window.miaKeyValues?.section?.join(",") || "",
    "channel_name": window.miaDfpAdUnit || "",
    "cb": String(Math.floor(Math.random() * (100000000 - 10000000)) + 10000000),
    "content_custom_1_param": window.miaKeyValues && encodeURIComponent(new URLSearchParams(window.miaKeyValues).toString()) || "",
    "ppid": window.URefId || "",
    language,
    "h": String(Math.round(container.offsetWidth/16*9)),
    "content_title": document.referrer || window.location.href,
    "coppa": window.coppa || "",
    "dnt": window.dnt || navigator.doNotTrack || "",
    "placement": "3",
    "ssid": window.springserveUserId || "",
    "ssregion": window.springserveRegion || "",
    ...policies,
    ...routers,
  };
  // add custom params
  if (iframeWindow.miaCustomParams) {
    params = { ...params, ...iframeWindow.miaCustomParams };
  }
  // remove empty params
  for (const [key, value] of Object.entries(params)) {
    if (value === undefined || value === "") {
      delete params[key];
    }
  }
  return new URLSearchParams(params).toString();
}

const createJWPlayer = (config: Config, iframeWindow: Window) => ({
  container: iframeWindow.document.querySelector(".wrapper").insertAdjacentElement("afterbegin", document.createElement("div")),
  delayedTimeouts: [],
  async setup(): Promise<Player> {
    this.jwplayer = iframeWindow.jwplayer(this.container);
    iframeWindow.jwplayer.key = JWPLAYER_KEY;
    iframeWindow.document.head.insertAdjacentHTML("beforeend", `<style>${skin}</style>`);
    await this.setConfig();
    if (config[config.mode].claimTextEnabled) {
      this.jwConfig.intl.en.advertising.displayHeading = config.displayOptions.claimText;
    }
    this.jwplayer.setup(this.jwConfig);
    this.attachEventHandlers();
    logConsole("JWPlayer setup done", ["JWPlayer config:", this.jwConfig])
    return this;
  },
  async getXML() {
    const tagURL = `${MPS_API}?${await getAdParams(this.container, config.routers, config.language, config.policies, iframeWindow)}${config.miaParams || ""}`;
    const response = await fetch(tagURL);
    let xmlString = await response.text();
    const xml = new DOMParser().parseFromString(xmlString, "text/xml");
    const mediaFiles = [...xml.querySelectorAll("MediaFile")];
    const mediaFile = mediaFiles.find((mediaFile) => mediaFile.getAttribute("width") >= this.container.offsetWidth) || mediaFiles[0];
    if (mediaFile) {
      this.mode = mediaFiles[0].getAttribute("apiFramework") === 'VPAID' ? "vpaid" : "vast";
      if (this.mode === "vpaid") {
        const adSystem = xml.querySelector("AdSystem").textContent;
        if ((adSystem === "VPAIDIMA3" || adSystem === 'DCM') && config.outstream) {
          config.mode = "outstream";
        } else {
          const adParameters = JSON.parse(xml.querySelector("AdParameters").textContent);
          config.mode = adParameters.carousel && config.carousel ? "carousel" : "native";
        }
        config.adSystem = adSystem;
        if (CHROME_RUNTIME_PATH) {
          xmlString = xmlString.replace(/https:\/\/(multiformat|s3\.us-west-2\.amazonaws.com\/application-mia-player-(dev|qa|prod))\.rubiconproject.com\/native\.js/, `${CHROME_RUNTIME_PATH}/native.js`);
        }
      } else if (config.outstream) {
        config.mode = "outstream";
      }
      this.ratio = `${Number(mediaFile.getAttribute("width"))}:${Number(mediaFile.getAttribute("height")) }`;
    } else {
      logConsole("No media files found in VAST response", ["Tag URL", tagURL]);
      throw new Error();
    }
    config.tag = tagURL;
    config.companions = parseBanners(xml);
    return xmlString;
  },
  async setConfig() {
    const xmlString = await this.getXML();
    this.jwConfig = {
      displayHeading: config.topWindow.ampSeen ? false : config[config.mode].claimTextEnabled,
      responsive: true,
      autostart: false,
      volume: 50,
      allowFullscreen: false,
      aspectratio: this.ratio,
      advertising: {
        vpaidmode: "insecure",
        vpaidcontrols: config.adSystem === "VPAIDIMA3" || config.adSystem === "DCM",
        outstream: true,
        client: "vast",
        autoplayadsmuted: true,
        withCredentials: false,
        vastxml: xmlString,
      },
      skin: {
        name: "magnite",
      },
      autoPause: {
        viewability: config.pauseOutOfScreen && config.mode === "outstream",
        pauseAds: true,
      },
      intl: {
        en: {
          advertising: {}
        },
      },
    };
  },
  attachEventHandlers() {
    this.jwplayer
      .on("ready", () => {
        this.container = this.jwplayer.getContainer();
        if (this.mode === "vast") {
          this.container.insertAdjacentHTML("afterbegin", `<a href="${MAGNITE_ICON_URL}" target="_blank" class="jw-brand-link">${MAGNITE_ICON}</a>`);
        }
        this.addCloseButton();
      })
      .on("adError", () => this.clearTimeouts())
      // pause other JW instances
      .on("beforePlay", () => {
        document.body.querySelectorAll(".jwplayer").forEach(({ id }) => {
          if (id !== this.jwplayer.id) window.jwplayer(id).pause();
        });

        // HACK to pause IMA ads on click
        if (config.adSystem === "VPAIDIMA3" || config.adSystem === "DCM") {
          const jwAspect = iframeWindow.document.querySelector(".jw-aspect") as HTMLElement;
          jwAspect.style.zIndex = "4";
          let state = "";
          this.jwplayer.on("adClick", () => {
            jwAspect.style.position = "relative";
            jwAspect.style.cursor = "pointer";
            state = "clicked";
            jwAspect.addEventListener("click", () => {
              jwAspect.style.position = "static";
              this.jwplayer.pauseAd(false);
            });
          })
            .on("viewable", () => {
              if (state === "clicked") {
                state = "paused";
                this.jwplayer.pauseAd(true);
              }
            });
        }
      });
  },
  addCloseButton() {
    const { script, enabled, delay } = config[config.mode].closeable;
    const addCloseButton = () => {
      this.container.insertAdjacentHTML("afterbegin", `<div class="jw-icon-close" ${config.mode !== "outstream" && `style="filter: invert(0.6)"`}>${CLOSE_ICON}</div>`);
      iframeWindow.document.querySelector(".jw-icon-close").addEventListener("click", () => {
        if (script) {
          const scriptElement = document.createElement("script");
          scriptElement.innerHTML = script;
          this.container.appendChild(scriptElement);
        }
        setTimeout(() => this.remove(), 100);
      });
    }
    const addTimer = (delay: number, callback: () => void) => {
      this.delayedTimeouts.push(setTimeout(() => callback(), delay * 1000 || 0));
    }
    if (enabled) {
      addTimer(delay, addCloseButton);
    }
    if (config.displayOptions.timer.enabled) {
      iframeWindow.document.querySelector<HTMLElement>(`.jw-button-container .jw-text[role="status"]`).style.display = "none";
      addTimer(config.displayOptions.timer.delay, () => {
        iframeWindow.document.querySelector<HTMLElement>(`.jw-button-container .jw-text[role="status"]`).style.display = "flex";
      });
    }
  },
  remove() {
    const { container } = config;
    container.parentElement.style.maxHeight = "0";
    if (this.floatingScrollListener) config.topWindow.removeEventListener("scroll", this.floatingScrollListener);
    const transitionHandler = () => {
      this.jwplayer.remove();
      if (config.displayOptions.preOpen) {
        container.remove();
      } else {
        container.parentElement.remove();
      }
      container.parentElement.removeEventListener("transitionend", transitionHandler);
    };
    container.parentElement.addEventListener("transitionend", transitionHandler);
    this.clearTimeouts();
  },
  clearTimeouts() {
    this.delayedTimeouts.forEach((timeout: NodeJS.Timeout) => clearTimeout(timeout));
  },
});

export default createJWPlayer;
